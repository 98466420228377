<template>
    <div
      @wheel.shift.prevent="zoom"
      class="view-location"
    >
      <progress
        class="top-progress"
        v-if="location.populatingPictures"
        :max="location.populatingPictures"
        :value="location.populatingPicturesProgress"
      />
      <h6 style="margin: 10px">Use shift+wheel to change zoom.</h6>
      <div
        v-if="location && location.pictures && showPictures"
        :key="location.id"
        class="thumbnail-container"
        :style="getGrid"
      >
        <template
          v-for="picture in location.pictures"
        >
          <router-link
            v-if="picture.image || picture.thumbnail"
            :key="picture.id"
            :to="'/picture/'+picture.id"
            tag="div"
            class="thumbnail"
          >
            <div style="position: absolute; bottom: 0px; z-index: 15; color: white; background-color: rgba(0,0,0,0.2); padding:5px; width: 100%; box-sizing:border-box;">
              {{ picture.file.name }}
            </div>
            <img
              v-if="picture.image"
              :src="picture.image"
              width="100%"
            />
            <img
              v-else-if="picture.thumbnail"
              :src="picture.thumbnail"
              width="100%"
            />
          </router-link>
        </template>
      </div>
      <div
        v-else
      >
        Loading...
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      showPictures: false,
      grid: 2,
      centered: false
    }
  },
  computed: {
    ...mapGetters(['locationById']),
    location () {
      const id = this.$route.params.id
      const location = this.$store.getters.locationById(id)
      return location
    },
    getGrid () {
      return {
        gridTemplateColumns: `repeat(${this.grid}, 1fr)`,
        fontSize: `${11 / (this.grid / 3)}px`
      }
    }
  },
  methods: {
    zoom (e) {
      if (e.deltaY > 0 && this.grid < 5) {
        this.grid += 1
      } else if (e.deltaY < 0 && this.grid > 0) {
        this.grid += -1
      }
    },
    fetchData () {
      if (!this.location) {
        this.$router.push({ path: '/' })
      }

      this.showPictures = false
      setTimeout(() => {
        this.showPictures = true
      }, 100)

      const location = this.location
      this.$store.dispatch('populateLocationPictures', location.id)
      this.$emit('setMenuTitle', this.$store.getters.locationNameById(location.id))
      this.$store.dispatch('setMapCenter', {
        latLng: location.latLng,
        zoom: 18
      })
    }
  },
  created () {
    this.fetchData()
  },
  destroyed () {
    this.$emit('resetMenuTitle')
    if (this.location) {
      this.$store.dispatch('cancelPopulateLocationPictures', this.location)
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (this.location) {
      this.$store.dispatch('cancelPopulateLocationPictures', this.location)
    }
    next()
  },
  watch: {
    $route: 'fetchData'
  }
}
</script>

<style lang="scss">
  .view-location {
    position:relative;
  }
  .thumbnail-container {
    display: grid;
  }
  .thumbnail {
    position: relative;
  }
</style>
